<template>
    <div>
        <el-form ref="queryForm" class="query-box" v-model="queryParams" :inline="true">
            <el-form-item label="代理商编号" prop="agentNo" label-width="120px">
                <el-input v-model="queryParams.agentNo" clearable placeholder="请输入代理商编号" style="width: 240px" size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery"
                    v-permission="'HIDE_DATA_AGENT_CONFIG_QUERY'">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="handleResetQuery">
                    重置
                </el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
                    v-permission="'HIDE_DATA_AGENT_CONFIG_INSERT'">
                    新增
                </el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleBatch('add')"
                    v-permission="'HIDE_DATA_AGENT_CONFIG_BATCH_INSERT'">
                    批量新增
                </el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleBatch('delete')"
                    v-permission="'HIDE_DATA_AGENT_CONFIG_DELETE'">
                    批量删除
                </el-button>
            </el-col>
        </el-row>

        <CustomForm :dicts="dicts" :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose" :isAdd="true">
        </CustomForm>
        <BatchForm :dicts="dicts" :on-fresh="showBatchForm" @on-close="handlerBatchFormOnclose" :type="type">
        </BatchForm>
    </div>
</template>

<script>
import CustomForm from "./CustomForm";
import BatchForm from "./BatchForm";
import { SpecialApi } from '@/api';
export default {
    name: "ToolBar",
    components: { CustomForm, BatchForm },
    props: {
        dicts: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            queryParams: {},
            createDateRange: [],
            updateDateRange: [],
            showCustomForm: false,
            showBatchForm: false,
            type: '',
        }
    },
    mounted() {
    },
    methods: {
        handlerCustomFormOnclose() {
            this.showCustomForm = false;
            this.commitChange();
        },
        handlerBatchFormOnclose() {
            this.showBatchForm = false;
            this.commitChange();
        },
        handleQuery() {
            this.commitChange();
        },
        handleResetQuery() {
            this.queryParams = {};
            this.createDateRange = [];
            this.updateDateRange = [];
            this.commitChange();
        },
        handleAdd() {
            this.showCustomForm = true;
        },
        handleExport() {
            this.$confirm("确定导出数据吗？",
                "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                this.dealWithQueryParams();
                let result = (await SpecialApi.hideDataAgentConfig.export(this.queryParams));
                if (result.success) {
                    this.downloadFile(result.data);
                }
            });
        },
        dealWithQueryParams() {
            let [startCreateTime, endCreateTime] = this.createDateRange;
            this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
            this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

            let [startUpdateTime, endUpdateTime] = this.updateDateRange;
            this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
            this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
        },
        commitChange() {
            this.dealWithQueryParams();
            let params = { ...this.queryParams };
            this.$emit('on-change', params);
        },
        handleBatch(val) {
            this.type = val;
            this.showBatchForm = true;
        },
    }
}
</script>

<style scoped></style>
